<script setup lang="ts">
import { useSlots, useAttrs } from 'vue';
import FlowbiteModal from '~/components/modals/flowbite/Modal/FlowbiteModal.vue';
import type { ModalSize } from '~/components/modals/flowbite/Modal/types';

defineOptions({
  inheritAttrs: false,
});

interface UhModalProps {
  open?: boolean;
  size?: ModalSize;
  bodyClasses?: string;
}

const props = withDefaults(defineProps<UhModalProps>(), {
  open: false,
  size: '2xl',
  bodyClasses: '',
});

const slots = useSlots();
const attrs = useAttrs();

const emits = defineEmits(['close']);
</script>

<template>
  <Teleport to="body">
    <FlowbiteModal
      v-if="props.open"
      v-bind="attrs"
      :size="props.size"
      popup
      @close="emits('close')"
    >
      <template v-if="slots.header" #header>
        <div class="flex items-center text-base font-semibold dark:text-white">
          <slot name="header"></slot>
        </div>
      </template>

      <template v-if="slots.body" #body>
        <div
          class="p-6 text-base leading-relaxed text-gray-900 dark:text-white"
          :class="bodyClasses"
        >
          <slot name="body"></slot>
        </div>
      </template>

      <template v-if="slots.footer" #footer>
        <div class="flex justify-between">
          <slot name="footer"></slot>
        </div>
      </template>
    </FlowbiteModal>
  </Teleport>
</template>
