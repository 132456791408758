import { default as testoU8xo7toDzMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/pages/test.vue?macro=true";
import { default as indexYNkl8l4CapMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/commissions/pages/index.vue?macro=true";
import { default as indexAhiiJbQ9mrMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/example/pages/index.vue?macro=true";
import { default as indexFoyfWsXy4nMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/index.vue?macro=true";
import { default as detailsQxehu0PyyQMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/personal/details.vue?macro=true";
import { default as password3tInmJLPGVMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/personal/password.vue?macro=true";
import { default as detailssci4QUKHr8Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/details.vue?macro=true";
import { default as enquiriesbnhNOMRGvVMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/enquiries.vue?macro=true";
import { default as utilitiesAix4V7AEhsMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/utilities.vue?macro=true";
import { default as tenanciesY3rmsBd2M5Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/tenancies.vue?macro=true";
import { default as managementH2w2J4RfFtMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/user/management.vue?macro=true";
import { default as addBwGIYakCWwMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/user/add.vue?macro=true";
import { default as indexBIVL0YJCJPMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/index.vue?macro=true";
import { default as enquiriesV7q6GLzSpqMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/enquiries.vue?macro=true";
import { default as utilitiesReUpBh8TTxMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/utilities.vue?macro=true";
import { default as discoveryTwkdaaToAtMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/discovery.vue?macro=true";
import { default as portfolioy5JUOZyNFFMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/portfolio.vue?macro=true";
import { default as marketaIsgrLGRqyMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/market.vue?macro=true";
import { default as indexceCfEfvu3HMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/login/index.vue?macro=true";
import { default as indexPBuZ9Uevy8Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/forgot-password/index.vue?macro=true";
import { default as sent8uOfNA53xkMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/forgot-password/sent.vue?macro=true";
import { default as _91token_93U0igMkjlpfMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/reset-password/[token].vue?macro=true";
import { default as featuresb488i4ijiQMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/checkout/features.vue?macro=true";
import { default as paymentTeOSGsAsaOMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/checkout/payment.vue?macro=true";
import { default as indexZxcv2uOg5fMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/index.vue?macro=true";
import { default as successKP0Ermfo32Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/success.vue?macro=true";
import { default as indexkJz0z0103UMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/dashboard/pages/index.vue?macro=true";
import { default as indexzIcEdUvFFoMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/enquiries/pages/index.vue?macro=true";
import { default as indexwLbcyiU5wKMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/index.vue?macro=true";
import { default as indexUM4NNjW0iBMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/import/index.vue?macro=true";
import { default as addressNiJejAu1D5Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/address.vue?macro=true";
import { default as aboutZRCzp0iHKqMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/about.vue?macro=true";
import { default as listingBRQrYqpH4HMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/listing.vue?macro=true";
import { default as mediaB3loxxNoUNMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/media.vue?macro=true";
import { default as propertyOib9mLqgS4Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property.vue?macro=true";
import { default as editHC1yQoZHWNMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/[uuid]/edit.vue?macro=true";
import { default as partnershipIx856KvxmZMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/resources/pages/partnership.vue?macro=true";
import { default as marketing_45materialQF4iffD6H3Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/resources/pages/marketing-material.vue?macro=true";
import { default as index5Pk3iyDz98Meta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/index.vue?macro=true";
import { default as detailsQzRgojvUTGMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/details.vue?macro=true";
import { default as paymentsheG1ryPjRYMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/payments.vue?macro=true";
import { default as tenantspyOpjvkoOpMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/tenants.vue?macro=true";
import { default as depositIO4hyQdr1YMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/deposit.vue?macro=true";
import { default as signing7jM8PCUobHMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/signing.vue?macro=true";
import { default as previewH5ut0jnbrPMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/preview.vue?macro=true";
import { default as agreementw4k6YICwfRMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement.vue?macro=true";
import { default as confirmation4FFAgOj8XLMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/confirmation.vue?macro=true";
import { default as indexNUoaYEQOrbMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/[uuid]/index.vue?macro=true";
import { default as indexGiKvOdq2UqMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/index.vue?macro=true";
import { default as detailsVQYoy3Qr5QMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/details.vue?macro=true";
import { default as tenantsedVNUfTCJnMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/tenants.vue?macro=true";
import { default as summaryfx9y7pdBbNMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/summary.vue?macro=true";
import { default as previewrQjoPtyFdEMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/preview.vue?macro=true";
import { default as orderNydWTziGHGMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order.vue?macro=true";
import { default as confirmation1FJE4mKaOJMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/confirmation.vue?macro=true";
import { default as indexOk3Us36iCiMeta } from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/[uuid]/index.vue?macro=true";
export default [
  {
    name: testoU8xo7toDzMeta?.name ?? "test",
    path: testoU8xo7toDzMeta?.path ?? "/test",
    meta: testoU8xo7toDzMeta || {},
    alias: testoU8xo7toDzMeta?.alias || [],
    redirect: testoU8xo7toDzMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/pages/test.vue").then(m => m.default || m)
  },
  {
    name: indexYNkl8l4CapMeta?.name ?? "commissions",
    path: indexYNkl8l4CapMeta?.path ?? "/commissions",
    meta: indexYNkl8l4CapMeta || {},
    alias: indexYNkl8l4CapMeta?.alias || [],
    redirect: indexYNkl8l4CapMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/commissions/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAhiiJbQ9mrMeta?.name ?? "example",
    path: indexAhiiJbQ9mrMeta?.path ?? "/example",
    meta: indexAhiiJbQ9mrMeta || {},
    alias: indexAhiiJbQ9mrMeta?.alias || [],
    redirect: indexAhiiJbQ9mrMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/example/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFoyfWsXy4nMeta?.name ?? "account-index",
    path: indexFoyfWsXy4nMeta?.path ?? "/account",
    meta: indexFoyfWsXy4nMeta || {},
    alias: indexFoyfWsXy4nMeta?.alias || [],
    redirect: indexFoyfWsXy4nMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/index.vue").then(m => m.default || m)
  },
  {
    name: detailsQxehu0PyyQMeta?.name ?? "personal-details-settings",
    path: detailsQxehu0PyyQMeta?.path ?? "/account/personal/details",
    meta: detailsQxehu0PyyQMeta || {},
    alias: detailsQxehu0PyyQMeta?.alias || [],
    redirect: detailsQxehu0PyyQMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/personal/details.vue").then(m => m.default || m)
  },
  {
    name: password3tInmJLPGVMeta?.name ?? "personal-password-settings",
    path: password3tInmJLPGVMeta?.path ?? "/account/personal/password",
    meta: password3tInmJLPGVMeta || {},
    alias: password3tInmJLPGVMeta?.alias || [],
    redirect: password3tInmJLPGVMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/personal/password.vue").then(m => m.default || m)
  },
  {
    name: detailssci4QUKHr8Meta?.name ?? "company-details-settings",
    path: detailssci4QUKHr8Meta?.path ?? "/account/company/details",
    meta: detailssci4QUKHr8Meta || {},
    alias: detailssci4QUKHr8Meta?.alias || [],
    redirect: detailssci4QUKHr8Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/details.vue").then(m => m.default || m)
  },
  {
    name: enquiriesbnhNOMRGvVMeta?.name ?? "company-enquiries-settings",
    path: enquiriesbnhNOMRGvVMeta?.path ?? "/account/company/enquiries",
    meta: enquiriesbnhNOMRGvVMeta || {},
    alias: enquiriesbnhNOMRGvVMeta?.alias || [],
    redirect: enquiriesbnhNOMRGvVMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/enquiries.vue").then(m => m.default || m)
  },
  {
    name: utilitiesAix4V7AEhsMeta?.name ?? "company-utilities-settings",
    path: utilitiesAix4V7AEhsMeta?.path ?? "/account/company/utilities",
    meta: utilitiesAix4V7AEhsMeta || {},
    alias: utilitiesAix4V7AEhsMeta?.alias || [],
    redirect: utilitiesAix4V7AEhsMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/utilities.vue").then(m => m.default || m)
  },
  {
    name: tenanciesY3rmsBd2M5Meta?.name ?? "company-tenancies-settings",
    path: tenanciesY3rmsBd2M5Meta?.path ?? "/account/company/tenancies",
    meta: tenanciesY3rmsBd2M5Meta || {},
    alias: tenanciesY3rmsBd2M5Meta?.alias || [],
    redirect: tenanciesY3rmsBd2M5Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/tenancies.vue").then(m => m.default || m)
  },
  {
    name: managementH2w2J4RfFtMeta?.name ?? "company-user-management",
    path: managementH2w2J4RfFtMeta?.path ?? "/account/company/user/management",
    meta: managementH2w2J4RfFtMeta || {},
    alias: managementH2w2J4RfFtMeta?.alias || [],
    redirect: managementH2w2J4RfFtMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/user/management.vue").then(m => m.default || m)
  },
  {
    name: managementH2w2J4RfFtMeta?.name ?? "company-user-history",
    path: managementH2w2J4RfFtMeta?.path ?? "/account/company/user/history",
    meta: managementH2w2J4RfFtMeta || {},
    alias: managementH2w2J4RfFtMeta?.alias || [],
    redirect: managementH2w2J4RfFtMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/user/management.vue").then(m => m.default || m)
  },
  {
    name: addBwGIYakCWwMeta?.name ?? "company-user-add",
    path: addBwGIYakCWwMeta?.path ?? "/account/company/user/add",
    meta: addBwGIYakCWwMeta || {},
    alias: addBwGIYakCWwMeta?.alias || [],
    redirect: addBwGIYakCWwMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/account/pages/company/user/add.vue").then(m => m.default || m)
  },
  {
    name: indexBIVL0YJCJPMeta?.name ?? "analytics",
    path: indexBIVL0YJCJPMeta?.path ?? "/analytics",
    meta: indexBIVL0YJCJPMeta || {},
    alias: indexBIVL0YJCJPMeta?.alias || [],
    redirect: indexBIVL0YJCJPMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/index.vue").then(m => m.default || m)
  },
  {
    name: enquiriesV7q6GLzSpqMeta?.name ?? "analytics-enquiries",
    path: enquiriesV7q6GLzSpqMeta?.path ?? "/analytics/enquiries",
    meta: enquiriesV7q6GLzSpqMeta || {},
    alias: enquiriesV7q6GLzSpqMeta?.alias || [],
    redirect: enquiriesV7q6GLzSpqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/enquiries.vue").then(m => m.default || m)
  },
  {
    name: utilitiesReUpBh8TTxMeta?.name ?? "analytics-utilities",
    path: utilitiesReUpBh8TTxMeta?.path ?? "/analytics/utilities",
    meta: utilitiesReUpBh8TTxMeta || {},
    alias: utilitiesReUpBh8TTxMeta?.alias || [],
    redirect: utilitiesReUpBh8TTxMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/utilities.vue").then(m => m.default || m)
  },
  {
    name: discoveryTwkdaaToAtMeta?.name ?? "analytics-discovery",
    path: discoveryTwkdaaToAtMeta?.path ?? "/analytics/discovery",
    meta: discoveryTwkdaaToAtMeta || {},
    alias: discoveryTwkdaaToAtMeta?.alias || [],
    redirect: discoveryTwkdaaToAtMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/discovery.vue").then(m => m.default || m)
  },
  {
    name: portfolioy5JUOZyNFFMeta?.name ?? "analytics-portfolio",
    path: portfolioy5JUOZyNFFMeta?.path ?? "/analytics/portfolio",
    meta: portfolioy5JUOZyNFFMeta || {},
    alias: portfolioy5JUOZyNFFMeta?.alias || [],
    redirect: portfolioy5JUOZyNFFMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: marketaIsgrLGRqyMeta?.name ?? "analytics-market",
    path: marketaIsgrLGRqyMeta?.path ?? "/analytics/market",
    meta: marketaIsgrLGRqyMeta || {},
    alias: marketaIsgrLGRqyMeta?.alias || [],
    redirect: marketaIsgrLGRqyMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/analytics/pages/market.vue").then(m => m.default || m)
  },
  {
    name: indexceCfEfvu3HMeta?.name ?? "login",
    path: indexceCfEfvu3HMeta?.path ?? "/login",
    meta: indexceCfEfvu3HMeta || {},
    alias: indexceCfEfvu3HMeta?.alias || [],
    redirect: indexceCfEfvu3HMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexPBuZ9Uevy8Meta?.name ?? "forgot-password",
    path: indexPBuZ9Uevy8Meta?.path ?? "/forgot-password",
    meta: indexPBuZ9Uevy8Meta || {},
    alias: indexPBuZ9Uevy8Meta?.alias || [],
    redirect: indexPBuZ9Uevy8Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: sent8uOfNA53xkMeta?.name ?? "forgot-password-sent",
    path: sent8uOfNA53xkMeta?.path ?? "/forgot-password/sent",
    meta: sent8uOfNA53xkMeta || {},
    alias: sent8uOfNA53xkMeta?.alias || [],
    redirect: sent8uOfNA53xkMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/forgot-password/sent.vue").then(m => m.default || m)
  },
  {
    name: _91token_93U0igMkjlpfMeta?.name ?? "reset-password",
    path: _91token_93U0igMkjlpfMeta?.path ?? "/reset-password/:token",
    meta: _91token_93U0igMkjlpfMeta || {},
    alias: _91token_93U0igMkjlpfMeta?.alias || [],
    redirect: _91token_93U0igMkjlpfMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/auth/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: indexZxcv2uOg5fMeta?.name ?? "checkout",
    path: indexZxcv2uOg5fMeta?.path ?? "/checkout",
    meta: indexZxcv2uOg5fMeta || {},
    alias: indexZxcv2uOg5fMeta?.alias || [],
    redirect: indexZxcv2uOg5fMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: featuresb488i4ijiQMeta?.name ?? "checkout-features",
    path: featuresb488i4ijiQMeta?.path ?? "features",
    meta: featuresb488i4ijiQMeta || {},
    alias: featuresb488i4ijiQMeta?.alias || [],
    redirect: featuresb488i4ijiQMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/checkout/features.vue").then(m => m.default || m)
  },
  {
    name: paymentTeOSGsAsaOMeta?.name ?? "checkout-payment",
    path: paymentTeOSGsAsaOMeta?.path ?? "payment",
    meta: paymentTeOSGsAsaOMeta || {},
    alias: paymentTeOSGsAsaOMeta?.alias || [],
    redirect: paymentTeOSGsAsaOMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/checkout/payment.vue").then(m => m.default || m)
  }
]
  },
  {
    name: successKP0Ermfo32Meta?.name ?? "checkout-success",
    path: successKP0Ermfo32Meta?.path ?? "/checkout/success",
    meta: successKP0Ermfo32Meta || {},
    alias: successKP0Ermfo32Meta?.alias || [],
    redirect: successKP0Ermfo32Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/checkout/pages/success.vue").then(m => m.default || m)
  },
  {
    name: indexkJz0z0103UMeta?.name ?? "dashboard",
    path: indexkJz0z0103UMeta?.path ?? "/",
    meta: indexkJz0z0103UMeta || {},
    alias: indexkJz0z0103UMeta?.alias || [],
    redirect: indexkJz0z0103UMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/dashboard/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexzIcEdUvFFoMeta?.name ?? "enquiries",
    path: indexzIcEdUvFFoMeta?.path ?? "/enquiries",
    meta: indexzIcEdUvFFoMeta || {},
    alias: indexzIcEdUvFFoMeta?.alias || [],
    redirect: indexzIcEdUvFFoMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/enquiries/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwLbcyiU5wKMeta?.name ?? "properties-index",
    path: indexwLbcyiU5wKMeta?.path ?? "/properties",
    meta: indexwLbcyiU5wKMeta || {},
    alias: indexwLbcyiU5wKMeta?.alias || [],
    redirect: indexwLbcyiU5wKMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUM4NNjW0iBMeta?.name ?? "properties-import",
    path: indexUM4NNjW0iBMeta?.path ?? "/properties/import",
    meta: indexUM4NNjW0iBMeta || {},
    alias: indexUM4NNjW0iBMeta?.alias || [],
    redirect: indexUM4NNjW0iBMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: propertyOib9mLqgS4Meta?.name ?? "properties-property-create",
    path: propertyOib9mLqgS4Meta?.path ?? "/property/create",
    meta: propertyOib9mLqgS4Meta || {},
    alias: propertyOib9mLqgS4Meta?.alias || [],
    redirect: {"name":"properties-property-create-address"},
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property.vue").then(m => m.default || m),
    children: [
  {
    name: addressNiJejAu1D5Meta?.name ?? "properties-property-create-address",
    path: addressNiJejAu1D5Meta?.path ?? "address",
    meta: addressNiJejAu1D5Meta || {},
    alias: addressNiJejAu1D5Meta?.alias || [],
    redirect: addressNiJejAu1D5Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutZRCzp0iHKqMeta?.name ?? "properties-property-create-about",
    path: aboutZRCzp0iHKqMeta?.path ?? "about",
    meta: aboutZRCzp0iHKqMeta || {},
    alias: aboutZRCzp0iHKqMeta?.alias || [],
    redirect: aboutZRCzp0iHKqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listingBRQrYqpH4HMeta?.name ?? "properties-property-create-listing",
    path: listingBRQrYqpH4HMeta?.path ?? "listing",
    meta: listingBRQrYqpH4HMeta || {},
    alias: listingBRQrYqpH4HMeta?.alias || [],
    redirect: listingBRQrYqpH4HMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediaB3loxxNoUNMeta?.name ?? "properties-property-create-media",
    path: mediaB3loxxNoUNMeta?.path ?? "media",
    meta: mediaB3loxxNoUNMeta || {},
    alias: mediaB3loxxNoUNMeta?.alias || [],
    redirect: mediaB3loxxNoUNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editHC1yQoZHWNMeta?.name ?? "properties-property-edit",
    path: editHC1yQoZHWNMeta?.path ?? "/property/:uuid/edit",
    meta: editHC1yQoZHWNMeta || {},
    alias: editHC1yQoZHWNMeta?.alias || [],
    redirect: {"name":"properties-property-edit-address"},
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/[uuid]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: addressNiJejAu1D5Meta?.name ?? "properties-property-edit-address",
    path: addressNiJejAu1D5Meta?.path ?? "address",
    meta: addressNiJejAu1D5Meta || {},
    alias: addressNiJejAu1D5Meta?.alias || [],
    redirect: addressNiJejAu1D5Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/address.vue").then(m => m.default || m)
  },
  {
    name: aboutZRCzp0iHKqMeta?.name ?? "properties-property-edit-about",
    path: aboutZRCzp0iHKqMeta?.path ?? "about",
    meta: aboutZRCzp0iHKqMeta || {},
    alias: aboutZRCzp0iHKqMeta?.alias || [],
    redirect: aboutZRCzp0iHKqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/about.vue").then(m => m.default || m)
  },
  {
    name: listingBRQrYqpH4HMeta?.name ?? "properties-property-edit-listing",
    path: listingBRQrYqpH4HMeta?.path ?? "listing",
    meta: listingBRQrYqpH4HMeta || {},
    alias: listingBRQrYqpH4HMeta?.alias || [],
    redirect: listingBRQrYqpH4HMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/listing.vue").then(m => m.default || m)
  },
  {
    name: mediaB3loxxNoUNMeta?.name ?? "properties-property-edit-media",
    path: mediaB3loxxNoUNMeta?.path ?? "media",
    meta: mediaB3loxxNoUNMeta || {},
    alias: mediaB3loxxNoUNMeta?.alias || [],
    redirect: mediaB3loxxNoUNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/properties/pages/property/media.vue").then(m => m.default || m)
  }
]
  },
  {
    name: partnershipIx856KvxmZMeta?.name ?? "resources-partnership",
    path: partnershipIx856KvxmZMeta?.path ?? "/resources/partnership",
    meta: partnershipIx856KvxmZMeta || {},
    alias: partnershipIx856KvxmZMeta?.alias || [],
    redirect: partnershipIx856KvxmZMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/resources/pages/partnership.vue").then(m => m.default || m)
  },
  {
    name: marketing_45materialQF4iffD6H3Meta?.name ?? "resources-marketing-material",
    path: marketing_45materialQF4iffD6H3Meta?.path ?? "/resources/marketing-material",
    meta: marketing_45materialQF4iffD6H3Meta || {},
    alias: marketing_45materialQF4iffD6H3Meta?.alias || [],
    redirect: marketing_45materialQF4iffD6H3Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/resources/pages/marketing-material.vue").then(m => m.default || m)
  },
  {
    name: index5Pk3iyDz98Meta?.name ?? "tenancies",
    path: index5Pk3iyDz98Meta?.path ?? "/tenancies",
    meta: index5Pk3iyDz98Meta || {},
    alias: index5Pk3iyDz98Meta?.alias || [],
    redirect: index5Pk3iyDz98Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreementw4k6YICwfRMeta?.name ?? "tenancies-agreement",
    path: agreementw4k6YICwfRMeta?.path ?? "/tenancies/agreement",
    meta: agreementw4k6YICwfRMeta || {},
    alias: agreementw4k6YICwfRMeta?.alias || [],
    redirect: {"name":"tenancies-agreement-details"},
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement.vue").then(m => m.default || m),
    children: [
  {
    name: detailsQzRgojvUTGMeta?.name ?? "tenancies-agreement-details",
    path: detailsQzRgojvUTGMeta?.path ?? "details",
    meta: detailsQzRgojvUTGMeta || {},
    alias: detailsQzRgojvUTGMeta?.alias || [],
    redirect: detailsQzRgojvUTGMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/details.vue").then(m => m.default || m)
  },
  {
    name: paymentsheG1ryPjRYMeta?.name ?? "tenancies-agreement-payments",
    path: paymentsheG1ryPjRYMeta?.path ?? "payments",
    meta: paymentsheG1ryPjRYMeta || {},
    alias: paymentsheG1ryPjRYMeta?.alias || [],
    redirect: paymentsheG1ryPjRYMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/payments.vue").then(m => m.default || m)
  },
  {
    name: tenantspyOpjvkoOpMeta?.name ?? "tenancies-agreement-tenants",
    path: tenantspyOpjvkoOpMeta?.path ?? "tenants",
    meta: tenantspyOpjvkoOpMeta || {},
    alias: tenantspyOpjvkoOpMeta?.alias || [],
    redirect: tenantspyOpjvkoOpMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/tenants.vue").then(m => m.default || m)
  },
  {
    name: depositIO4hyQdr1YMeta?.name ?? "tenancies-agreement-deposit",
    path: depositIO4hyQdr1YMeta?.path ?? "deposit",
    meta: depositIO4hyQdr1YMeta || {},
    alias: depositIO4hyQdr1YMeta?.alias || [],
    redirect: depositIO4hyQdr1YMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/deposit.vue").then(m => m.default || m)
  },
  {
    name: signing7jM8PCUobHMeta?.name ?? "tenancies-agreement-signing",
    path: signing7jM8PCUobHMeta?.path ?? "signing",
    meta: signing7jM8PCUobHMeta || {},
    alias: signing7jM8PCUobHMeta?.alias || [],
    redirect: signing7jM8PCUobHMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/signing.vue").then(m => m.default || m)
  },
  {
    name: previewH5ut0jnbrPMeta?.name ?? "tenancies-agreement-preview",
    path: previewH5ut0jnbrPMeta?.path ?? "preview",
    meta: previewH5ut0jnbrPMeta || {},
    alias: previewH5ut0jnbrPMeta?.alias || [],
    redirect: previewH5ut0jnbrPMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexNUoaYEQOrbMeta?.name ?? "tenancies-agreement-edit",
    path: indexNUoaYEQOrbMeta?.path ?? "/tenancies/agreement/:uuid",
    meta: indexNUoaYEQOrbMeta || {},
    alias: indexNUoaYEQOrbMeta?.alias || [],
    redirect: {"name":"tenancies-agreement-edit-details"},
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/[uuid]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsQzRgojvUTGMeta?.name ?? "tenancies-agreement-edit-details",
    path: detailsQzRgojvUTGMeta?.path ?? "details",
    meta: detailsQzRgojvUTGMeta || {},
    alias: detailsQzRgojvUTGMeta?.alias || [],
    redirect: detailsQzRgojvUTGMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/details.vue").then(m => m.default || m)
  },
  {
    name: paymentsheG1ryPjRYMeta?.name ?? "tenancies-agreement-edit-payments",
    path: paymentsheG1ryPjRYMeta?.path ?? "payments",
    meta: paymentsheG1ryPjRYMeta || {},
    alias: paymentsheG1ryPjRYMeta?.alias || [],
    redirect: paymentsheG1ryPjRYMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/payments.vue").then(m => m.default || m)
  },
  {
    name: tenantspyOpjvkoOpMeta?.name ?? "tenancies-agreement-edit-tenants",
    path: tenantspyOpjvkoOpMeta?.path ?? "tenants",
    meta: tenantspyOpjvkoOpMeta || {},
    alias: tenantspyOpjvkoOpMeta?.alias || [],
    redirect: tenantspyOpjvkoOpMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/tenants.vue").then(m => m.default || m)
  },
  {
    name: depositIO4hyQdr1YMeta?.name ?? "tenancies-agreement-edit-deposit",
    path: depositIO4hyQdr1YMeta?.path ?? "deposit",
    meta: depositIO4hyQdr1YMeta || {},
    alias: depositIO4hyQdr1YMeta?.alias || [],
    redirect: depositIO4hyQdr1YMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/deposit.vue").then(m => m.default || m)
  },
  {
    name: signing7jM8PCUobHMeta?.name ?? "tenancies-agreement-edit-signing",
    path: signing7jM8PCUobHMeta?.path ?? "signing",
    meta: signing7jM8PCUobHMeta || {},
    alias: signing7jM8PCUobHMeta?.alias || [],
    redirect: signing7jM8PCUobHMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/signing.vue").then(m => m.default || m)
  },
  {
    name: previewH5ut0jnbrPMeta?.name ?? "tenancies-agreement-edit-preview",
    path: previewH5ut0jnbrPMeta?.path ?? "preview",
    meta: previewH5ut0jnbrPMeta || {},
    alias: previewH5ut0jnbrPMeta?.alias || [],
    redirect: previewH5ut0jnbrPMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/preview.vue").then(m => m.default || m)
  },
  {
    name: confirmation4FFAgOj8XLMeta?.name ?? "tenancies-agreement-edit-confirmation",
    path: confirmation4FFAgOj8XLMeta?.path ?? "confirmation",
    meta: confirmation4FFAgOj8XLMeta || {},
    alias: confirmation4FFAgOj8XLMeta?.alias || [],
    redirect: confirmation4FFAgOj8XLMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/tenancies/pages/agreement/confirmation.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexGiKvOdq2UqMeta?.name ?? "utilities",
    path: indexGiKvOdq2UqMeta?.path ?? "/utilities",
    meta: indexGiKvOdq2UqMeta || {},
    alias: indexGiKvOdq2UqMeta?.alias || [],
    redirect: indexGiKvOdq2UqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/index.vue").then(m => m.default || m)
  },
  {
    name: orderNydWTziGHGMeta?.name ?? "utilities-order",
    path: orderNydWTziGHGMeta?.path ?? "/utilities/order",
    meta: orderNydWTziGHGMeta || {},
    alias: orderNydWTziGHGMeta?.alias || [],
    redirect: {"name":"utilities-order-details"},
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order.vue").then(m => m.default || m),
    children: [
  {
    name: detailsVQYoy3Qr5QMeta?.name ?? "utilities-order-details",
    path: detailsVQYoy3Qr5QMeta?.path ?? "details",
    meta: detailsVQYoy3Qr5QMeta || {},
    alias: detailsVQYoy3Qr5QMeta?.alias || [],
    redirect: detailsVQYoy3Qr5QMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantsedVNUfTCJnMeta?.name ?? "utilities-order-tenants",
    path: tenantsedVNUfTCJnMeta?.path ?? "tenants",
    meta: tenantsedVNUfTCJnMeta || {},
    alias: tenantsedVNUfTCJnMeta?.alias || [],
    redirect: tenantsedVNUfTCJnMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryfx9y7pdBbNMeta?.name ?? "utilities-order-summary",
    path: summaryfx9y7pdBbNMeta?.path ?? "summary",
    meta: summaryfx9y7pdBbNMeta || {},
    alias: summaryfx9y7pdBbNMeta?.alias || [],
    redirect: summaryfx9y7pdBbNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: previewrQjoPtyFdEMeta?.name ?? "utilities-order-preview",
    path: previewrQjoPtyFdEMeta?.path ?? "preview",
    meta: previewrQjoPtyFdEMeta || {},
    alias: previewrQjoPtyFdEMeta?.alias || [],
    redirect: previewrQjoPtyFdEMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexOk3Us36iCiMeta?.name ?? "utilities-order-edit",
    path: indexOk3Us36iCiMeta?.path ?? "/utilities/order/:uuid",
    meta: indexOk3Us36iCiMeta || {},
    alias: indexOk3Us36iCiMeta?.alias || [],
    redirect: {"name":"utilities-order-edit-details"},
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/[uuid]/index.vue").then(m => m.default || m),
    children: [
  {
    name: detailsVQYoy3Qr5QMeta?.name ?? "utilities-order-edit-details",
    path: detailsVQYoy3Qr5QMeta?.path ?? "details",
    meta: detailsVQYoy3Qr5QMeta || {},
    alias: detailsVQYoy3Qr5QMeta?.alias || [],
    redirect: detailsVQYoy3Qr5QMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/details.vue").then(m => m.default || m)
  },
  {
    name: tenantsedVNUfTCJnMeta?.name ?? "utilities-order-edit-tenants",
    path: tenantsedVNUfTCJnMeta?.path ?? "tenants",
    meta: tenantsedVNUfTCJnMeta || {},
    alias: tenantsedVNUfTCJnMeta?.alias || [],
    redirect: tenantsedVNUfTCJnMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/tenants.vue").then(m => m.default || m)
  },
  {
    name: summaryfx9y7pdBbNMeta?.name ?? "utilities-order-edit-summary",
    path: summaryfx9y7pdBbNMeta?.path ?? "summary",
    meta: summaryfx9y7pdBbNMeta || {},
    alias: summaryfx9y7pdBbNMeta?.alias || [],
    redirect: summaryfx9y7pdBbNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/summary.vue").then(m => m.default || m)
  },
  {
    name: previewrQjoPtyFdEMeta?.name ?? "utilities-order-edit-preview",
    path: previewrQjoPtyFdEMeta?.path ?? "preview",
    meta: previewrQjoPtyFdEMeta || {},
    alias: previewrQjoPtyFdEMeta?.alias || [],
    redirect: previewrQjoPtyFdEMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/preview.vue").then(m => m.default || m)
  },
  {
    name: confirmation1FJE4mKaOJMeta?.name ?? "utilities-order-edit-confirmation",
    path: confirmation1FJE4mKaOJMeta?.path ?? "confirmation",
    meta: confirmation1FJE4mKaOJMeta || {},
    alias: confirmation1FJE4mKaOJMeta?.alias || [],
    redirect: confirmation1FJE4mKaOJMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/utilities/pages/order/confirmation.vue").then(m => m.default || m)
  }
]
  }
]