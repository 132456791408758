import revive_payload_client_4sVQNw7RlN from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vapor_pIQavGOz06 from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/support/vapor/runtime/plugins/vapor.ts";
import stripe_client_zGdfFdiFbE from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/support/stripe/runtime/plugins/stripe.client.ts";
import plugin_Fgx7Ax6uz0 from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/support/echo/runtime/plugin.ts";
import plugin_Rz8rTBCrlc from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/modules/support/nuxt-sanctum-auth/runtime/plugin.ts";
import pwa_client_Eorgdr12jA from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_8SbxDRbG6Y from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import fontawesome_cn2c4tOOHz from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/fontawesome.ts";
import growthbook_Iy8w9k9ghu from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/growthbook.ts";
import middleware_2wzLuOatkW from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/middleware.ts";
import modaler_zty5jgybqw from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/modaler.ts";
import pageTransition_lo8TYkQBOw from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/pageTransition.ts";
import pinia_48xmdi2HHl from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/pinia.ts";
import ray_yW6CJrBkXG from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/ray.ts";
import sentry_client_shVUlIjFLk from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/sentry.client.ts";
import userback_client_VpKvEefojn from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/userback.client.ts";
import vueDatePicker_GfeYHwmpe2 from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/vueDatePicker.ts";
import vueGoogleMaps_8xmoAwKZvr from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/vueGoogleMaps.ts";
import ziggy_SNjz8uXtjk from "/var/lib/jenkins/workspace/Vapor/github_agents_deploy_prod/agents/plugins/ziggy.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vapor_pIQavGOz06,
  stripe_client_zGdfFdiFbE,
  plugin_Fgx7Ax6uz0,
  plugin_Rz8rTBCrlc,
  pwa_client_Eorgdr12jA,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  fontawesome_cn2c4tOOHz,
  growthbook_Iy8w9k9ghu,
  middleware_2wzLuOatkW,
  modaler_zty5jgybqw,
  pageTransition_lo8TYkQBOw,
  pinia_48xmdi2HHl,
  ray_yW6CJrBkXG,
  sentry_client_shVUlIjFLk,
  userback_client_VpKvEefojn,
  vueDatePicker_GfeYHwmpe2,
  vueGoogleMaps_8xmoAwKZvr,
  ziggy_SNjz8uXtjk
]