<script setup lang="ts">
import { provide, ref, computed, type ComputedRef } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { twMerge } from 'tailwind-merge';
import type { UseLayoutInjection } from '~/components/layout/types';
import { LAYOUT_INJECTION_KEY } from '~/components/layout/constants';
import UhModaler from '~/components/modals/UhModaler.vue';

const drawer = ref(false);
const defaultClasses = ref('sm:bg-gray-50');
const minSupportedWidth: number = 640;

const { width } = useWindowSize();

const isSupportedWidth: ComputedRef<boolean> = computed(() => {
  return width.value >= minSupportedWidth;
});

function toggleDrawer() {
  drawer.value = !drawer.value;
}

function mergeWithDefaultClasses(classes: string) {
  defaultClasses.value = twMerge(defaultClasses.value, classes);
}

provide<UseLayoutInjection>(LAYOUT_INJECTION_KEY, {
  drawer,
  toggleDrawer,
  defaultClasses,
  mergeWithDefaultClasses,
  isSupportedWidth,
  minSupportedWidth,
});

const auth = useAuth();
const { $sentrySetUser } = useNuxtApp();

$sentrySetUser({ id: auth.user?.id });
</script>

<template>
  <div>
    <VitePwaManifest />
    <NuxtLayout>
      <UhModaler />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
