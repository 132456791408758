import { useCookie, useFetch, useRuntimeConfig } from '#app';
import type { ResponseError } from './../../types/index';
// import type { FetchError } from 'ofetch';

// type UseFetchType = typeof useFetch;

//   type UseFetchOptions<DataT> = {
//     key?: string
//     method?: string
//     query?: SearchParams
//     params?: SearchParams
//     body?: RequestInit['body'] | Record<string, any>
//     headers?: Record<string, string> | [key: string, value: string][] | Headers
//     baseURL?: string
//     server?: boolean
//     lazy?: boolean
//     immediate?: boolean
//     default?: () => DataT
//     transform?: (input: DataT) => DataT
//     pick?: string[]
//     watch?: WatchSource[]
//   }

//   type AsyncData<DataT, ErrorT> = {
//     data: Ref<DataT | null>
//     pending: Ref<boolean>
//     refresh: (opts?: AsyncDataExecuteOptions) => Promise<void>
//     execute: (opts?: AsyncDataExecuteOptions) => Promise<void>
//     error: Ref<ErrorT | null>
//     status: Ref<AsyncDataRequestStatus>
//   }

//   interface AsyncDataExecuteOptions {
//     dedupe?: boolean
//   }

//   type AsyncDataRequestStatus = 'idle' | 'pending' | 'success' | 'error'

// export function useNewApiFetch<ResT = void, ErrorT = FetchError>(
//   request: string | Request | Ref<string | Request>,
//   options: UseFetchOptions<ResT>,
// ): UseFetchType {
//   const config = useRuntimeConfig().public.apiFetch;
//   const { $showToast, $sanctumAuth } = useNuxtApp();

//   return useFetch<ResT, ErrorT>(request, {
//     baseURL: config.baseUrl,
//     credentials: 'include',
//     headers: {
//       ...config.headers,
//       [config.csrf.headerKey]: useCookie(config.csrf.cookieKey).value,
//     } as HeadersInit,

//     // Error interceptors
//     onResponseError({ response }) {
//       // TODO - PC - Check we dont have 401 responses for anything other than unauthenticated
//       if (response.status === 401) {
//         $sanctumAuth.logout();
//       }

//       if (response.status === 400) {
//         $showToast(
//           UhToast,
//           {
//             closable: true,
//             message:
//               response._data.error ?? 'An unexpected error has occurred.',
//             type: 'danger',
//           },
//           10_000,
//         );
//       }
//     },
//     // Apply argument options
//     ...options,
//   });
// }

type UseFetch = typeof useFetch;

type UseApiFetch = UseFetch & {
  showDangerToast?: boolean;
};

export const useApiFetch: UseApiFetch = (
  url: string,
  opts?: Record<string, any> | null | undefined,
) => {
  const config = useRuntimeConfig().public.apiFetch;

  const { $sanctumAuth, $sentryCaptureException } = useNuxtApp();
  const toaster = useToaster();

  return useFetch(url, {
    baseURL: config.baseUrl,
    credentials: 'include',
    headers: {
      ...config.headers,
      [config.csrf.headerKey]: useCookie(config.csrf.cookieKey).value,
    } as HeadersInit, // Error interceptors
    onResponseError({
      response,
    }: {
      request: any;
      response: ResponseError;
      options: any;
    }) {
      // TODO - PC - Check we dont have 401 responses for anything other than unauthenticated
      if (
        [401, 419].includes(response.status) &&
        !['/userback', '/me'].includes(url)
      ) {
        // @ts-ignore
        $sanctumAuth.logout();
        toaster.add('danger', 'Session has expired');
      } else if (!['/userback', '/me'].includes(url)) {
        // @ts-ignore
        $sentryCaptureException(response);
      }
    },
    // Apply argument options
    ...opts,
  });
};
